import React from "react";
 
const Logo = () => {
    return(
        <div className="logo">
            <svg enableBackground="new 0 0 500.07 355.13" version="1.1" viewBox="0 0 500.07 355.13" >

                <path d="M424.01,0v143.06h-37.94V0h-76.08v0l-38.04,0c11.57,0.72,20.76,4.39,27.27,11.35c6.89,7.36,10.41,17.87,10.77,31.32v312.45   h76.08V206.48h37.94v148.65h38.02c11.57-0.72,20.76-4.39,27.27-11.35c6.89-7.36,10.41-17.87,10.77-31.32V0H424.01z"/>
                <path d="m271.95 312.45v-312.45h-126.54l-19.05 214.6-19.05-214.6h-69.27c-11.57 0.72-20.76 4.39-27.27 11.35-6.9 7.36-10.41 17.87-10.77 31.32v312.45h56.82v-235.4l38.06 235.4h62.96l38.03-232.86v232.86h76.08 38.04c-11.57-0.72-20.76-4.39-27.27-11.35-6.9-7.36-10.41-17.87-10.77-31.32z"/>

            </svg>
        </div>
    )
}
export default Logo;